import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import vibeImage from "../images/vibe.png"

export default function StemGym() {
  return (
    <PageWrapper>
      <Wrapper>
        <BackgroundImage>
          <TitleBox>
            <Title>VIBE</Title>
            <SubTitle>fest med dine venner👀</SubTitle>
          </TitleBox>
          <ButtonBox>
            <DescText>lancerer på første gym 14/5</DescText>

            <a
              style={{ textDecorationLine: "none" }}
              href="https://m.me/joinvibe?ref=w15109973"
            >
              <Button>
                <ButtonText>stem på gym</ButtonText>
              </Button>
            </a>
          </ButtonBox>
        </BackgroundImage>
        <AmbassadorBox>
          <AmbassadorTitle>bliv ambassadør👇</AmbassadorTitle>
          <AmbassadorButtonBox>
            <a
              style={{ textDecorationLine: "none" }}
              href="https://form.typeform.com/to/iWHk8M6Z"
            >
              <Button>
                <ButtonText>ansøg nu</ButtonText>
              </Button>
            </a>
          </AmbassadorButtonBox>
        </AmbassadorBox>
      </Wrapper>
    </PageWrapper>
  )
}

// Page Wrapper
const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #121212;
`

// Content wrapper
const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  align-self: center;
  background-color: #121212;
  align-items: center;
  justify-content: center;
`

const BackgroundImage = styled.div`
  background-image: url(${vibeImage});
  background-color: #121212;
  width: 100vw;
  max-width: 600px;
  min-height: 600px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  display: flex;
  justify-content: center;
  aling-items: center;
  flex-direction: column;
`
const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.p`
  font-family: Gotham;
  font-size: 55px;
  letter-spacing: 2px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  color: #fdde68;
`
const SubTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 28px;
  width: 200px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  flex-direction: column;
`

const DescText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  width: 160px;
  margin-bottom: 25px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const GreyText = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #8a8a8a;
  font-size: 18px;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
  margin-bottom: 25px;
`

const Button = styled.div`
  width: 170px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #fdde68;
`

const ButtonText = styled.p`
  text-align: center;
  vertical-align: middle;
  margin: 0;
  margin-bottom: 1px;
  text-decoration: none;
  color: #333;

  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
  font-weight: 900;
  font-size: 20px;
  line-height: 50px;
`
const AmbassadorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 60px;
  align-self: center;
  background-color: #121212;
`
const AmbassadorTitle = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 28px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
`

const AmbassadorDesc = styled.p`
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 20px;
  width: 250px;
  margin-bottom: 8px;
  text-align: center;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin: 0;
`

const AmbassadorButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
